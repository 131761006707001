import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import { getCoinStoreList, getStripeToken, createXsollaTransaction, payUHash, getCoinStoreListIndia } from "../../../Utilities/controller";
import { func } from "../../../Utilities/logFunc";
import { url } from "../../../Utilities/url";
import Loader from "../../Common/Loader";
import TransactionIcon from "../../../assets/images/recent-transaction-icon.png";
import MyTransactionModal from "./MyTransactionModal";
import Diamond from "../../../assets/images/diamond-icon.png";
import { useTranslation } from "react-i18next";
import PaymentModal from "./PaymentModal";
import { toast } from "react-toastify";
import getSymbolFromCurrency from "currency-symbol-map";
import { useProfileDataContext } from "./../ProfileContext";
import EmailVerificationModal from "../EmailVerification/EmailVerificationModal";
import { eventTracking } from "../../../firebaseAnalytics";
import { events } from "../../../Utilities/appEvents";
import PayuModal from "./PayuModal";
const Bundle = ({ props, selectedType }) => {
  const { t } = useTranslation();
  let countryObj = JSON.parse(localStorage.getItem("profile"))?.country;
  const [bundleList, setBundleList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [showMTM, setShowMTM] = useState(false);
  const [coinDetails, setCoinDetails] = useState('');
  const [stripeToken, setStripeToken] = useState();
  const [showPayment, setShowPayment] = useState(false);
  const { profileData, updateProfileData, liteProfile } = useProfileDataContext();
  const [emailVerificationModal, setEmailVerificationModal] = useState(false);
  const [showPayuForm, setShowPayuForm] = useState(false)
  const [payUData, setPayUData] = useState(null)
  const fetchBundleList = async (pageNo) => {
    setLoading(true);
    try {
      let data = {
        // filter: { coinType: "2" },
        group_id: 2,
        country: countryObj?.code,
        skip: 0,
        limit: 10,
        // sort: "asc",
        // sortBy: "amount",
      };

      const res = await getCoinStoreList(data);

      if (res?.data.success) {
        console.log(res.data);
        setBundleList(res?.data?.items);
        // setTotalPage(res?.data?.totalPages);
        setLoading(false);
      }
      func(res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      setLoading(false);
    }
  };

  const onCLickCoinStorePayU = async (item) => {
    // 
    // console.log(liteProfile)
    if (liteProfile?.item?.isEmailVerified && liteProfile?.item?.isMobileVerified) {
      try {
        let payload = {
          key: process.env.REACT_APP_KEY,
          amount: item?.amount,
          productId: item?._id,
          email: liteProfile?.item?.email,
          phone: liteProfile?.item?.phone,
          productInfo: process.env.REACT_APP_COIN_PRODUCT,
          surl: process.env.REACT_APP_BASE_URL + "api/webapp/payu/payment-status",
          furl: process.env.REACT_APP_BASE_URL + "api/webapp/payu/payment-status",
          userId: liteProfile?.item?._id
          // firstName: liteProfile?.item?._id,

        };
        // console.log(payload)
        let res = await payUHash(payload);
        // console.log(res)
        if (res?.data?.success) {
          payload = { ...payload, hash: res?.data?.hash, txnid: res?.data?.transactionID }
          setPayUData(payload)
          // console.log("setDat")
          setShowPayuForm(true)
          // console.log(payload)
        }
      } catch (error) {

      }
    }
    else {
      setEmailVerificationModal(true);
    }

  }
  const fetchCoinListForIndia = async (pageNo) => {
    setLoading(true);
    try {
      let data = {
        filter: { coinType: "2" },
        skip: 0,
        limit: 10,
        sort: "asc",
        sortBy: "amount",
      };

      const res = await getCoinStoreListIndia(data);
      // console.log(res);
      if (res?.data.success) {
        console.log(res?.data)
        setBundleList(res?.data?.list);
        // setTotalPage(res?.data?.totalPages);
        setLoading(false);
      }
      func("coinpack", res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (selectedType === "bundles-tab") {
      // console.log(localStorage.getItem('country') == '611e04284ac17121fd8b1a54')
      if (localStorage.getItem('country') == '611e04284ac17121fd8b1a54')
        fetchCoinListForIndia(1);
      else
        fetchBundleList(1);
    }
  }, [selectedType]);


  const onClickBundleStore = async (item) => {
    if (liteProfile?.item?.isEmailVerified && liteProfile?.item?.isMobileVerified) {
      setLoading(true);
      try {
        let body = {
          sku: item.sku,
          userCurrency: countryObj?.code,
          // amount: parseInt(amount),
          amount: item?.price?.amount,
          type: 2,
          isMobile: false
        };
        const res = await createXsollaTransaction(body);
        if (res.data.success) {
          // setStripeToken(res.data.item.clientSecret);
          // setCoinDetails(item);
          // setShowPayment(true);
          let body = {
            coin: item.description,
            sku: item.sku,
            amount: item?.price?.amount,
          };
          localStorage.setItem("xsolla-body", JSON.stringify(body));
          localStorage.setItem("currecySymbol", item?.price?.currency);
          eventTracking(events.Buy_Bundle, { GJ_BundleID: item._id });
          eventTracking(events.Pay_now, { GJ_BundleID: item._id, GJ_BundleAmount: item?.price?.amount });

          setLoading(false);
          let url = `https://secure.xsolla.com/paystation4/?token=${res?.data?.data.token}`;
          window.location.href = url;
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.errors[0]?.msg)

      }
    } else {
      setEmailVerificationModal(true);
    }
  };
  const sendRequest = (res) => {
    setEmailVerificationModal(false);
  };
  // const onClickBundleStore = async (item) => {
  //   setLoading(true);
  //   try {
  //     let body = {
  //       "coinStore": item._id
  //     }
  //     const res = await getStripeToken(body);
  //     if(res.data.success) {
  //       setStripeToken(res.data.item.clientSecret);
  //       setCoinDetails(item);
  //       setShowPayment(true);
  //       setLoading(false);
  //     } else {
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     // console.log(error)
  //     toast.error(error?.response?.data?.errors[0]?.msg)
  //     setLoading(false);
  //   }
  // };

  return (
    <>
      {(
        <>
          {bundleList?.length > 0 && (
            <div class="col-12" style={{ marginTop: "3rem" }}>
              <div class="mb-4 d-flex align-items-center justify-content-between">
                <h2 class="h3 bodyBoldFont mb-0">
                  {" "}
                  {t("coin_store.combo_bundles")}
                </h2>
                <button
                  className="d11-recenttran"
                  onClick={() => setShowMTM(true)}
                >
                  <img
                    src={TransactionIcon}
                    class="transaction-logo"
                    alt="transaction"
                  />
                  {t("reward_store.my_recent_transactions")}
                </button>
              </div>
              <div class="row g-20 mb-3">
                {bundleList.map((item, ind) => (
                  <div class="col-lg-4 mw-lg-20 col-md-4 col-6">
                    <div
                      class="card bundleCard"
                    // style={{padding:'10px'}}
                    >
                      {console.log(item)}
                      <div class="row gap text-center">
                        <div class="col-12 my-3">
                          <div className="p-3">
                            <img
                              className="w-100"
                              src={item.image_url ? item.image_url : url.imageUrl + item?.avatar?.img?.default}
                              alt={item?.avatar?.name ? item?.avatar?.name : item?.name}
                            />
                          </div>
                        </div>
                        <div class="col-12">
                          <h6 class="mb-1 h5 text-white">
                            {item?.avatar?.name ? item?.avatar?.name : item?.name}
                          </h6>
                          <h6 class="mb-1 h5 text-white">+</h6>
                          <h6 class="mb-1 h5 text-white">
                            {item?.description ? item?.description : item?.coins}

                            <span class="mx-2">
                              {/* <img className="icon-20" src={Diamond} /> */}
                              <img className="icon-20" src={url.imageUrl + profileData?.item?.wallet[profileData?.item?.wallet?.findIndex((obj) => obj.currencyData?.code == "coin")]?.currencyData?.img?.default}
                                alt="Avatar"
                              />
                            </span>
                          </h6>
                        </div>
                        <div class="col-12 mb-1">
                          <div class="d-grid">
                            <button
                              class="btn text-white btn-earncoin mx-2 my-3"
                              onClick={() => {
                                if (localStorage.getItem('country') == '611e04284ac17121fd8b1a54')
                                  onCLickCoinStorePayU(item)
                                else
                                  onClickBundleStore(item)
                              }}
                            >
                              <h6 class="mb-1 h5 text-white d-flex justify-content-center align-items-center">
                                {item?.isPurchase
                                  ? t("coin_store.purchased")
                                  : `${item?.price?.amount ? getSymbolFromCurrency(item?.price?.currency) : item?.currency?.symbol
                                  } ${item?.price?.amount ? item?.price?.amount : item?.amount}`}
                              </h6>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {showMTM && (
            <MyTransactionModal showMTM={showMTM} setShowMTM={setShowMTM} />
          )}
          {showPayuForm && <PayuModal showPayuForm={showPayuForm} setShowPayuForm={setShowPayuForm} payload={payUData} />}
          {showPayment && <PaymentModal {...props} stripeToken={stripeToken} coinDetails={coinDetails} showPayment={showPayment} setShowPayment={() => setShowPayment(false)} />}
          {isLoading ? <Loader /> : <></>}
          {emailVerificationModal && (
            <EmailVerificationModal
              emailVerificationModal={emailVerificationModal}
              setEmailVerificationModal={setEmailVerificationModal}
              sendRequest={sendRequest}
              tagMobile={liteProfile?.item?.isMobileVerified ? false : true}
              tagEmail={liteProfile?.item?.isEmailVerified ? false : true}
            />
          )}
        </>
      )}
    </>
  );
};
export default Bundle;
