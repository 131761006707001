import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { getCoinStoreList, getStripeToken, createXsollaTransaction, getCoinStoreListIndia, payUHash } from "../../../Utilities/controller";
import { func } from "../../../Utilities/logFunc";
import { url } from "../../../Utilities/url";
import Loader from "../../Common/Loader";
import TransactionIcon from '../../../assets/images/recent-transaction-icon.png'
import MyTransactionModal from "./MyTransactionModal";
import PaymentModal from "./PaymentModal";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Diamond from "../../../assets/images/diamond-icon.png";
// import Diamond2 from "../../../assets/images/Diamond2.png";
import Diamond2 from "../../../assets/images/purchase-pack-icon.png";
import { useTranslation } from "react-i18next";
import { useProfileDataContext } from "./../ProfileContext";
import getSymbolFromCurrency from "currency-symbol-map";
import EmailVerificationModal from "../EmailVerification/EmailVerificationModal";
import { eventTracking } from "../../../firebaseAnalytics";
import { events } from "../../../Utilities/appEvents";
import PayuModal from "./PayuModal";
const CoinPack = ({ props, selectedType }) => {
  const [coinList, setCoinList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [showMTM, setShowMTM] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [coinDetails, setCoinDetails] = useState('');
  const [stripeToken, setStripeToken] = useState();
  const { profileData, updateProfileData, liteProfile } = useProfileDataContext();
  let countryObj = JSON.parse(localStorage.getItem("profile"))?.country;
  const [emailVerificationModal, setEmailVerificationModal] = useState(false);
  const [showPayuForm, setShowPayuForm] = useState(false)
  const [selectedItem, setSelecetedItem] = useState(null)
  const [payUData, setPayUData] = useState(null)
  const { t } = useTranslation();
  const fetchCoinList = async (pageNo) => {
    setLoading(true);
    try {
      let data = {
        // filter: { coinType: "1" },
        group_id: 1,
        country: countryObj?.code,
        skip: 0,
        limit: 10,
        // sort: "asc",
        // sortBy: "amount",
      };

      const res = await getCoinStoreList(data);
      // console.log(res);
      if (res?.data.success) {
        // console.log()
        setCoinList(res?.data?.items);
        // setTotalPage(res?.data?.totalPages);
        setLoading(false);
      }
      func("coinpack", res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      setLoading(false);
    }
  };

  const fetchCoinListForIndia = async (pageNo) => {
    setLoading(true);
    try {
      let data = {
        filter: { coinType: "1" },
        skip: 0,
        limit: 10,
        sort: "asc",
        sortBy: "amount",
      };

      const res = await getCoinStoreListIndia(data);
      // console.log(res);
      if (res?.data.success) {
        // console.log()
        setCoinList(res?.data?.list);
        // setTotalPage(res?.data?.totalPages);
        setLoading(false);
      }
      func("coinpack", res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedType === "packs-tab") {
      if (localStorage.getItem('country') == '611e04284ac17121fd8b1a54')
        fetchCoinListForIndia(1);
      else
        fetchCoinList(1);
    }
  }, [selectedType]);
  // const onClickCoinStore = async(item) => {
  //   setLoading(true);
  //   try {
  //     let body = {
  //       "coinStore": item._id
  //     }
  //     const res = await getStripeToken(body);
  //     if(res.data.success) {
  //       setStripeToken(res.data.item.clientSecret);
  //       setCoinDetails(item);
  //       setShowPayment(true);
  //       setLoading(false);
  //     } else {
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     // console.log(error)
  //     toast.error(error?.response?.data?.errors[0]?.msg)
  //     setLoading(false);
  //   }
  // }

  const onClickCoinStore = async (item) => {
    if ((liteProfile?.item?.isEmailVerified && liteProfile?.item?.isMobileVerified) || (selectedItem && selectedItem !== null)) {
      setLoading(true);
      try {
        let body = {
          sku: item.sku,
          userCurrency: countryObj?.code,
          // amount: parseInt(amount),
          amount: item?.price?.amount,
          type: 1,
          isMobile: false
        };
        const res = await createXsollaTransaction(body);
        console.group(res.data)
        if (res.data.success) {
          // setStripeToken(res.data.item.clientSecret);
          // setCoinDetails(item);
          // setShowPayment(true);
          let body = {
            coin: item.description,
            sku: item.sku,
            amount: item?.price?.amount,
          };
          localStorage.setItem("xsolla-body", JSON.stringify(body));
          localStorage.setItem("currecySymbol", item?.price?.currency);
          eventTracking(events.Buy_Packs, { GJ_PackID: item._id });
          eventTracking(events.Pay_now, { GJ_PackID: item._id, GJ_PAckAmount: item?.price?.amount });

          setLoading(false);
          let url = `https://secure.xsolla.com/paystation4/?token=${res?.data?.data.token}`;
          window.location.href = url;
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.errors[0]?.msg)
      }
    } else {
      setSelecetedItem(item)
      setEmailVerificationModal(true);
    }

  }
  const onCLickCoinStorePayU = async (item) => {
    // 
    // console.log("item>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",item)
    if ((liteProfile?.item?.isEmailVerified && liteProfile?.item?.isMobileVerified) || (selectedItem && selectedItem !== null)) {
      try {
        let payload = {
          key: process.env.REACT_APP_KEY,
          amount: item?.amount,
          productId: item?._id,
          email: liteProfile?.item?.email,
          phone: liteProfile?.item?.phone,
          productInfo: process.env.REACT_APP_COIN_PRODUCT,
          surl: process.env.REACT_APP_BASE_URL + 'api/webapp/payu/payment-status',
          furl: process.env.REACT_APP_BASE_URL + 'api/webapp/payu/payment-status',
          curl: process.env.REACT_APP_BASE_URL + 'api/webapp/payu/payment-status',
          userId: liteProfile?.item?._id,
          coins: item?.coins,
          description: item?.description,
          platform: "webportal"

        };
        let res = await payUHash(payload);
        if (res?.data?.success && res?.data?.hash && res?.data?.transactionID) {
          // console.log("HASH:::",res?.data?.hash)
          payload = { ...payload, hash: res?.data?.hash, txnid: res?.data?.transactionID }
          setTimeout(() => {
            setPayUData(payload)
            setShowPayuForm(true)
          }, 500)

        } else {
          console.log("HASH:::NOT GET HASH")
        }
      } catch (error) {

      }
    }
    else {
      setSelecetedItem(item)
      setEmailVerificationModal(true);
    }

  }
  const sendRequest = (res) => {
    if (res) {
      let item = selectedItem
      setSelecetedItem(item)
      setEmailVerificationModal(false);
      if (localStorage.getItem('country') == '611e04284ac17121fd8b1a54')
        onCLickCoinStorePayU(item)
      else
        onClickCoinStore(item)
    }
  }



  return (
    <>
      {(
        <>
          {coinList?.length > 0 ? (
            <div class="col-12" style={{ marginTop: "3rem" }}>
              <div class="mb-4  d-flex align-items-center justify-content-between">
                <h2 class="h3 bodyBoldFont mb-0"> {t("coin_store.coin_packs")}</h2>
                <button
                  className="d11-recenttran"
                  onClick={() => setShowMTM(true)}
                >
                  <img
                    src={TransactionIcon}
                    class="transaction-logo"
                    alt=""
                  />
                  {t("reward_store.my_recent_transactions")}
                </button>
              </div>
              <div class="row g-20 mb-3">
                {coinList.map((item, ind) => (
                  <div class="col-lg-4 col-6 mw-lg-20 col-md-6" key={ind}>
                    <div class="card earncoinCard">
                      <div class="row gap text-center"
                      // style={{height:'100%'}}
                      >
                        <div class="col-12 mt-3 mb-4">
                          <img
                            src={Diamond2}
                            style={{ height: "5.625rem", width: "8.625rem" }}
                            alt=""
                          />
                        </div>
                        <div class="col-12 mb-4">
                          <h6 class="mb-1 h5 text-white d-flex justify-content-center align-items-center">
                            {item?.description ? item?.description : item?.coins}
                            <span class="mx-2">
                              {/* <img src={Diamond} className="icon-30"/> */}
                              <img src={url.imageUrl + profileData?.item?.wallet[profileData?.item?.wallet?.findIndex((obj) => obj.currencyData?.code == "coin")]?.currencyData?.img?.default} className="icon-30" alt="Gld" />
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <g clip-path="url(#clip0_2639_4171)">
                                  <path
                                    d="M10 0C4.47728 0 0 4.47728 0 10C0 15.5227 4.47728 20 10 20C15.5227 20 20 15.5227 20 10C20 4.47728 15.5227 0 10 0ZM15.5374 15.5374C14.166 16.9086 12.3347 17.7222 10.398 17.8209C8.46122 17.9196 6.55673 17.2962 5.05314 16.0715H3.05173L2.13968 16.066L2.87062 13.5766H3.0295C2.36924 12.2906 2.07774 10.8469 2.18726 9.40549C2.29678 7.96403 2.80305 6.58099 3.65004 5.40949C4.49702 4.23798 5.65166 3.32371 6.98615 2.76789C8.32063 2.21206 9.7829 2.03636 11.2111 2.26024C12.6392 2.48412 13.9776 3.09885 15.078 4.03637C16.1784 4.97389 16.9979 6.19763 17.4457 7.57211C17.8936 8.9466 17.9524 10.4182 17.6156 11.824C17.2788 13.2299 16.5595 14.5151 15.5374 15.5374Z"
                                    fill="#FFCD05"
                                  />
                                  <path
                                    d="M11.701 4.52344H6.1701L4.23972 11.033C4.23972 11.033 4.34817 11.0395 4.53037 11.0395C5.41205 11.0395 8.02511 10.8942 8.50716 9.19592C9.03964 7.31815 9.14592 7.01015 9.14592 7.01015H10.0704C11.0313 7.01015 11.6559 7.22524 11.9444 7.65542C12.1201 7.91786 12.1788 8.31713 12.1206 8.85323H10.6371L10.3058 9.77504H11.9406C11.9179 9.86288 11.8934 9.95181 11.8669 10.0462C11.8202 10.2027 11.7734 10.3518 11.7264 10.4935H10.0856L9.75431 11.4153H11.3545C11.2633 11.6043 11.1543 11.7843 11.0291 11.9527C10.7305 12.3453 10.3938 12.6204 10.0189 12.778C9.64406 12.9356 9.06982 13.0153 8.29623 13.0171H3.63296L2.90039 15.5065L3.8119 15.5119H8.4589C9.11935 15.5119 9.88499 15.4122 10.7558 15.2126C11.3924 15.068 12.0341 14.777 12.6808 14.3396C13.0479 14.0913 13.5744 13.5957 13.7539 13.4232C14.1985 12.9607 14.6307 12.2363 14.9896 11.4169H16.4298L16.7611 10.4951H15.3513C15.4327 10.2576 15.5075 10.0174 15.5736 9.77883H16.9835L17.3142 8.85702H15.7867C15.931 8.06264 15.9548 7.33875 15.8046 6.82688C15.7629 6.67993 15.655 6.33344 15.5037 6.09268C15.2145 5.5884 14.7755 5.20052 14.1866 4.92903C13.5977 4.65755 12.7692 4.52308 11.701 4.52561V4.52344Z"
                                    fill="#FFCD05"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_2639_4171">
                                    <rect width="20" height="20" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg> */}
                            </span>
                          </h6>
                        </div>
                        <div class="col-12 ">


                          <div class="d-grid px-2">
                            <button class="btn text-white btn-earncoin d-flex align-items-center justify-content-center" onClick={() => {
                              if (localStorage.getItem('country') == '611e04284ac17121fd8b1a54')
                                onCLickCoinStorePayU(item)
                              else
                                onClickCoinStore(item)
                            }
                            }>
                              {/* <img style={{width:'15px', height:'15px'}} src={ url?.imageUrl +item?.currency?.img?.default} /> */}
                              {/* {item?.currency?.symbol} */}
                              {item?.price?.amount ? getSymbolFromCurrency(item?.price?.currency) : item?.currency?.symbol}
                              <span className="h4 mb-0 ms-1">
                                {item?.price?.amount ? item?.price?.amount : item?.amount}
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* {showPayment && <PaymentModal {...props} stripeToken={stripeToken} coinDetails={coinDetails} showPayment={showPayment} setShowPayment={() => setShowPayment(false)}/>} */}
          {showMTM && (
            <MyTransactionModal showMTM={showMTM} setShowMTM={setShowMTM} />
          )}
          {emailVerificationModal && (
            <EmailVerificationModal
              emailVerificationModal={emailVerificationModal}
              setEmailVerificationModal={setEmailVerificationModal}
              sendRequest={sendRequest}
              tagMobile={liteProfile?.item?.isMobileVerified ? false : true}
              tagEmail={liteProfile?.item?.isEmailVerified ? false : true}
            />
          )}
        </>
      )}
      {isLoading ? <Loader /> : <></>}
      {showPayuForm && <PayuModal showPayuForm={showPayuForm} setShowPayuForm={setShowPayuForm} payload={payUData} />}
    </>
  );
};
export default CoinPack;
