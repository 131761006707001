export const routesBackend = {
  countries: "country-list",
  avatarFree: "avatars/free-list",
  login: "auth/login",
  emailLogin: "auth/send-email-otp",
  emailLogin2: "auth/login-email-otp",
  signup: "auth/signup",
  googleLogin: "google-login",
  // signup: "auth/signup",
  banners: "banners/list",
  tournamentList: "tournaments/list",
  html5SettingsList: "html5-settings/list",
  gameList: "game/list",
  accountProfile: "account/profile",
  gameTypeList: "gameType/list",
  applycode: "public/apply-singup-code",
  createProfile: "account/profile/update",
  list: "list",
  get: "get/",
  getInGameName: "inGameName/byUser",
  addInGameName: "inGameName/add",
  updateDobState: "account/update-dob-state",
  Banner: "/tournaments/:id",
  unsubscribe : "/account/unsubscription",

  // How To Play
  howToPlay: "how-to-play",
  howToJoin: "how-to-play/getHowto",
  //scrim
  getGameTypeScrim: "getGameType",
  getScrimGameRules: "GameRules",
  createScrim: "createScrim",
  viewScrimById: "viewScrimById",
  getStatus: "GetStatus",
  getLeaderBorad: "getLeaderBorad",
  roomIdAndPassword: "updateRoomIdPassword",
  leaderboardToday: "account/leaderboard-today",
  leaderboardWeekly: "account/leaderboard-weekly",
  leaderboardMonthly: "account/leaderboard-monthly",
  leaderboardAllOver: "account/leaderboard-allOver",
  contestList: "contest/list",
  walletLimit: "account/wallet-usage-limit",
  userProfileList: "users/list",
  blogList: "blogs/list",
  liveStreamingsList: "live-streamings/list",
  scrimListByUserId: "scrim/ScrimListByUserId",
  walletUsageLimit: "account/wallet-usage-limit",
  gamerjiTournamentJoin: "gamerjiTournament/join",
  gamerjiTournamentGet: "gamerjiTournament/get",
  gamerjiTournamentGetSummery: "tournaments/tournamentInfo",
  gamerjiTournamentRoundMatch: "tournaments/tournamentRounds",
  getgamerjiTournamentRules: "tournaments/tournamentRules",
  getContestRate: "account/get-contest-rate",
  eventLeaderBoards: "tournaments/eventLeaderBoard",
  addContestRate: "/account/contest-rate",
  //scrim

  uploadSS: "tickets/upload",
  ssUpload: "SSupload/",
  updateWinners: "updateWinners",
  getScrimById: "getScrimById",
  updateScrim: "updateScimById",

  joinContest: "joinUserContest/join",
  contestDetail: "contest/get",
  contestInfo: "contest/getContestInfo",
  contestRules: "contest/getContestRules",
  contestUserList: "joinedContestUserByContestId",

  getCustomerCareCategory: "customercare/category/list",
  ticketList: "tickets/list",
  getSearchGame: "games/search",
  userContestList: "contests/user-contest-list",
  ticketUpload: "tickets/upload",
  ticketCreate: "tickets/create",

  joinUserContestList: "contest/myContestList",
  gamerjiTournamentList: "gamerjiTournament/my-list",
  gamerjiTournamentRules: "gamerjiTournament/rules",
  scrimByUserId: "scrim/getScrimByUserId",
  stateList: "public/state-list",

  applyPromoCode: "account/apply-promo-code",
  myRewardList: "reward-products/my-list",
  rewardCategoryList: "reward-categories/list",
  contestByCode: "contest/get-by-code/",
  joiScrim: "joinScrim",
  scrimUserDetails: "getUserDetail",
  updateScrimRating: "updateScrimRating",
  html5GameCategoryList: "html5-categories/list",
  html5GameList: "html5-games/list",
  html5GamesUpdateView: "html5-games/update-view",

  applyReward: "account/apply-reward",

  joinViaInviateCodeContest: "contest/get-by-code",
  joinViaInviateCodeTournament: "tournaments/contest-event-by-code",

  gamerjiPointsCategory: "point-categories/list",
  gamerjiPointList: "points/list",

  privacyPolicy: "page/privacy-policy",
  termsOfUse: "page/terms-and-conditions",

  followUser: "account/follow-user",

  coinStoreList: "coin-stores/list",
  xsollaList: "xsolla/list",

  avatarList: "avatar-categories/list",
  buyAvatar: "account/buy-avatar",
  joinScrim: "joinScrim",
  transactionList: "transactions/list",

  getAds: "adDownload/getAds",
  updateCoin: "adDownload/updateCoin",
  dailyRewardsList: "daily-rewards/list",
  accountDailyRewards: "account/daily-rewards",

  accountCheckEmail: "account/check-email",
  levelList: "levels/list",
  collegeList: "colleges/list",
  statistics: "account/game-statistics",
  collectDailyLoginReward: "account/collect-daily-rewards",
  isUserPremium: "subscription/isUserPremium/",
  contestByGame: "account/contest-by-game",
  rankSummeryByGame: "account/rank-summery-by-game",
  createPaymentIntent: "account/create-payment-intent",
  createStripeTransaction: "account/create-stripe-transaction-web",
  ticketsGet: "tickets/get/",
  ticketUpdate: "tickets/update/",
  getSubscriptionTypeList: "subscription/getSubscriptionTypeList",
  getTimeSlots: "subscription/getTimeSlots",
  statusUpdate: "scrim/updateStatus/",
  createStripeSubscriptionTransaction:
    "account/create-subscription-transaction",
  updateStripeSubscriptionTransaction:
    "account/update-subscription-transaction",
  getHowToCreateScrim: "getHowToCreateScrim",
  getHeaderApi: "account/header-data",
  getFeaturedTournament: "tournaments/featuredTournaments",
  getGameTypeList: "gameType/gameTypeList",
  favGameList: "game/favGame/list",
  collegeUserList: "users/collegeList",
  getGamePlayed: 'game/getPlayedGame',
  createNewsLetter: "news-letter/create",
  createContactUs: "contact-us/create",
  getConstantProfile: "account/lite-profile",
  createTangerineSubscription: "account/create-tangerine-subscription",
  getLanguage: 'language/get',
  createXsollaTrns: 'account/create-xsolla-transaction',
  updateXsollaTrns: 'account/update-xsolla-transaction',
  createSubXsollaTrns: 'account/create-xsolla-subscription-transaction',
  updateSubXsollaTrns: 'account/update-xsolla-subscription-transaction',
  sendOTP: "account/send-otp",
  validateOtp:"account/validate-otp",
  bannerAdLog: "account/banner-ad-log",
  gamePBE: "external-platform/game/get/PBE",
  gamePBL: "external-platform/game/get/PBL",
  payUHash :'payu/payment-hash',
  payUWebhook :'payu/payment-payu-webhook',
  createPayPayment :'payu/create-payu-payment'

};
